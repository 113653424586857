import { useState, useEffect } from "react";
import { initializeApp, getApp, getApps } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import DeviceDetector from "device-detector-js";
import { toast } from "react-toastify";
import { handlePostApiCall } from "./utils/api.call";

const firebaseConfig = {
  apiKey: "AIzaSyAzZfgrR0FdYJ4OpJL7PQ0ZeVwFu8-hWpk",
  authDomain: "web-api-notif.firebaseapp.com",
  projectId: "web-api-notif",
  storageBucket: "web-api-notif.appspot.com",
  messagingSenderId: "760840812639",
  appId: "1:760840812639:web:74577457da47b6614103ea",
  measurementId: "G-3SGVZZ87GW",
};

let app = null;
let messaging = null;

function initFirebase() {
  if (!getApps().length) {
    app = initializeApp(firebaseConfig);
  } else {
    app = getApp();
  }

  isSupported().then((isSupp) => {
    if (!isSupp) return;

    messaging = getMessaging(app);
  });
}

function usePermitNotifications() {
  const [permission, setPermission] = useState("");

  useEffect(() => {
    isSupported().then((supported) => {
      if (!supported) {
        setPermission("unavailable");
        return;
      }
      setPermission(Notification.permission);
    }).catch((err) => {
      setPermission("unavailable");
    });
  });

  function handlePermission() {
    if (permission === "denied" || permission === "unavailable") return;

    isSupported()
      .then((isSupp) => {
        if (!isSupp) {
          return;
        }

        getToken(messaging)
          .then((token) => {
            const deviceType = new DeviceDetector().parse(navigator.userAgent)
              .device.type;

            handlePostApiCall({
              url: `notification/register`,
              params: {
                device_type: deviceType,
                device_token: token,
              },
              handleSuccessFn: (res) => {
                if (res?.data?.status === "success") {
                  toast("You will now receive chat notifications", {
                    className: "Toastify__toast__background-success",
                    bodyClassName: "toast__body",
                    autoClose: 3000,
                  });
                } else if (res?.data?.status === "failed") {
                  toast(res?.data?.message, {
                    className: "Toastify__toast__background-danger",
                    bodyClassName: "toast__body",
                    autoClose: 3000,
                  });
                }
              },
            });
          })
          .catch((err) => {
            // To sentry
            throw new Error("Failed to register device", err);
          })
          .finally(() => setPermission(Notification.permission));
      })
      .catch((err) => {
        throw new Error("Failed to check support for FCM", err);
      });
  }

  return { permission, handlePermission };
}

/**
 * @returns {boolean | null}
 * Returns null if the check is not yet complete
 */
function useIsMessagingSupported() {
  const [isMsgSupported, setIsMsgSupported] = useState(null);

  useEffect(() => {
    isSupported().then((isSupp) => {
      setIsMsgSupported(isSupp);
    });
  }, []);

  return isMsgSupported;
}

export {
  initFirebase,
  messaging,
  getToken,
  usePermitNotifications,
  useIsMessagingSupported,
};
