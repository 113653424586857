import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  IconButton,
  Typography,
  Popover,
  Button,
  ThemeProvider,
} from "@mui/material";
import { ChevronLeft, Close } from "@mui/icons-material";
import Image from "next/image";
import MsgBubble from "../../../public/images/icons/msg_bubble.svg";
import Archive from "../../../public/images/icons/archive.svg";
import HappySmile from "../../../public/images/icons/happy_smile.svg";
import { parseChannelObj, parseMessengerProfileObj } from "../chat";
import { toast } from "react-toastify";
import UITooltip from "../../ui/tooltip";
import { handleGetApiCall, handlePostApiCall } from "../../../utils/api.call";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import theme from "../../../styles/themes/default";
import { getDateInHyphenSeparatorFormat } from "../../../utils/date";
import { useRouter } from "next/router";
import styles from "../chat.module.css";

function StatusUntilDatePicker() {
  const dispatch = useDispatch();
  const statusChangeData = useSelector(
    (state) => state.ChatReducer.statusChangeData
  );

  if (!statusChangeData?.isDatePickerOpen) return <></>;

  function handleOnChange(date) {
    dispatch({
      type: "SET_STATUS_CHANGE_DATA",
      payload: {
        ...statusChangeData,
        statusActiveUntil: date,
      },
    });
  }

  function handleCancelClick() {
    dispatch({
      type: "RESET_STATUS_CHANGE_DATA",
    });
  }

  function handleConfirmClick() {
    function handleSuccessFn(res) {
      dispatch({
        type: "RESET_STATUS_CHANGE_DATA",
      });
      dispatch({
        type: "SET_MESSENGER_PROFILE",
        payload: parseMessengerProfileObj(res?.data),
      });
    }

    handlePostApiCall({
      url: `messenger/profile/update-status`,
      params: {
        id: statusChangeData?.statusId,
        active_until: getDateInHyphenSeparatorFormat(
          statusChangeData?.statusActiveUntil
        ),
      },
      handleSuccessFn,
    });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <StaticDatePicker
          minDate={new Date().setDate(new Date().getDate() + 1)}
          value={statusChangeData.statusActiveUntil}
          onChange={handleOnChange}
          showToolbar={false}
          showDaysOutsideCurrentMonth
          components={{
            ActionBar: () => (
              <Box className={styles.chatSection__datePickerBtnCtn}>
                <Button
                  className={styles.chatSection__datePicker__btn}
                  variant="contained"
                  sx={{
                    color: "white",
                  }}
                  onClick={handleConfirmClick}
                >
                  Confirm
                </Button>
              </Box>
            ),
          }}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

function ChatSectionHeader({
  handleChatClose = () => {},
  handleChannelClose = () => {},
  ctnClassName = "",
  disableClose = false,
  disableBack = false,
  hideMsgBubble = false,
  enableArchive = false,
  disableActiveChannel = false,
  enableStatus = false,
}) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.AuthReducer.profile);
  const messengerProfile = useSelector(
    (state) => state.ChatReducer.messengerProfile
  );
  const channels = useSelector(
    (state) => state.ChatReducer.channelData.channels
  );
  const activeChannel = useSelector(
    (state) => state.ChatReducer.channelData.activeChannel
  );
  const tempChannel = useSelector(
    (state) => state.ChatReducer.channelData.tempChannel
  );
  const statusChangeData = useSelector(
    (state) => state.ChatReducer.statusChangeData
  );

  const [statusOptions, setStatusOptions] = useState([]);
  const statusOptionsRef = useRef(null);

  useEffect(() => {
    if (!enableStatus) return;

    function handleSuccessFn(res) {
      setStatusOptions(res?.data ?? []);
    }

    handleGetApiCall("messenger/profile/status/presets", {}, handleSuccessFn);
  }, [enableStatus]);

  function handleStatusClick() {
    dispatch({
      type: "SET_STATUS_CHANGE_DATA",
      payload: {
        ...statusChangeData,
        isOptionsOpen: !statusChangeData.isOptionsOpen,
      },
    });
  }

  function handleArchiveClick() {
    if (!activeChannel || !activeChannel?.id) return;
    const channel = channels?.find((ch) => ch.id === activeChannel.id);
    if (!channel || !channel.id) return;

    function handleSuccessFn(res) {
      if (!res?.data?.channel_id) return;

      const updatedChannel = parseChannelObj(res?.data, profile);
      if (updatedChannel.id === activeChannel?.id) {
        dispatch({ type: "SET_ACTIVE_CHANNEL", payload: updatedChannel });
      }

      if (!channels?.length) return;
      const updatedChannelList = channels?.map((ch) =>
        ch.id === updatedChannel.id ? updatedChannel : ch
      );
      dispatch({ type: "SET_CHANNELS", payload: updatedChannelList });
      toast(
        updatedChannel.isArchived ? "Archived channel" : "Unarchived channel",
        {
          className: "Toastify__toast__background-success",
          bodyClassName: "toast__body",
          autoClose: 3000,
        }
      );
    }

    const params = {
      is_archived: !channel.isArchived,
    };
    handlePostApiCall({
      url: `messenger/channel/${channel.id}/update-archive-status`,
      params: params,
      handleSuccessFn: handleSuccessFn,
    });
  }

  function handleStatusChoiceClick(option) {
    if (!option || !option.id) return;

    if (option.id === "available") {
      function handleSuccessFn(res) {
        dispatch({
          type: "RESET_STATUS_CHANGE_DATA",
        });
        dispatch({
          type: "SET_MESSENGER_PROFILE",
          payload: parseMessengerProfileObj(res?.data),
        });
      }

      handlePostApiCall({
        url: `messenger/profile/update-status`,
        params: {
          id: option.id,
          active_until: getDateInHyphenSeparatorFormat(new Date()),
        },
        handleSuccessFn,
      });
      return;
    }

    dispatch({
      type: "SET_STATUS_CHANGE_DATA",
      payload: {
        ...statusChangeData,
        isOptionsOpen: false,
        isDatePickerOpen: true,
        statusId: option.id,
      },
    });
  }

  function handleBackClick() {
    if (enableStatus && profile?.isHost && statusChangeData?.isDatePickerOpen) {
      dispatch({
        type: "RESET_STATUS_CHANGE_DATA",
      });
    } else if (activeChannel?.id || tempChannel) {
      handleChannelClose();
    }
  }

  return (
    <Box
      className={[
        styles.chatSection__headerCtn,
        !hideMsgBubble &&
          (!activeChannel?.id || tempChannel) &&
          styles.chatSection__headerCtn__padLeft,
        ,
        ctnClassName,
      ].join(" ")}
    >
      <Box className={[styles.chatSection__headerSubCtn].join(" ")}>
        {(!disableBack && activeChannel?.id) ||
        tempChannel ||
        (enableStatus &&
          profile?.isHost &&
          statusChangeData?.isDatePickerOpen) ? (
          <IconButton onClick={handleBackClick} sx={{ padding: "4px" }}>
            <ChevronLeft
              className={styles.chatSection__headerBackIcon}
              color="secondary"
            />
          </IconButton>
        ) : (
          !hideMsgBubble && <Image src={MsgBubble} priority />
        )}
        <Typography className={styles.chatSection__headerText} noWrap>
          {disableActiveChannel
            ? "Chats"
            : enableStatus &&
              profile?.isHost &&
              statusChangeData?.isDatePickerOpen
            ? "Set timings"
            : activeChannel?.id || tempChannel
            ? activeChannel?.name || tempChannel?.name
            : "Chats"}
        </Typography>
      </Box>
      <Box className={styles.chatSection__headerRightCtn}>
        {enableArchive && (
          <UITooltip title="Archive Chat">
            <IconButton onClick={handleArchiveClick}>
              <Image src={Archive} priority />
            </IconButton>
          </UITooltip>
        )}
        {enableStatus &&
          profile?.isHost &&
          !statusChangeData?.isDatePickerOpen && (
            <>
              <IconButton onClick={handleStatusClick} ref={statusOptionsRef}>
                <Image src={HappySmile} priority />
              </IconButton>
              <Popover
                open={statusChangeData.isOptionsOpen}
                onClose={handleStatusClick}
                anchorEl={statusOptionsRef.current}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  className: styles.chatSection__statusPopover,
                }}
                disableScrollLock
              >
                <Typography
                  className={styles.chatSection__statusPopover__title}
                >
                  Set Status
                </Typography>
                <Box className={styles.chatSection__statusOptionsCtn}>
                  {statusOptions
                    .filter(
                      (option) => option.id !== messengerProfile?.status?.id
                    )
                    .map((option) => (
                      <Button
                        key={option.id}
                        className={styles.chatSection__statusOption}
                        onClick={() => handleStatusChoiceClick(option)}
                      >
                        {option.name}
                      </Button>
                    ))}
                </Box>
              </Popover>
            </>
          )}

        {!disableClose && (
          <IconButton onClick={handleChatClose}>
            <Close color="secondary" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

export { StatusUntilDatePicker };
export default ChatSectionHeader;
