import { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import {
  parseAgentObj,
  parseChannelObj,
  parseMessageObj,
  parseMessengerProfileObj,
} from "./chat";
import { handleGetApiCall, handlePostApiCall } from "../../utils/api.call";
import useIsMobile from "../../utils/device.type.hook";

const PING_INTERVAL = {
  IN_CHAT: 2,
  IN_CHANNEL_LIST: 10,
  DEFAULT: 30,
};

function useFetchLatestMsgs() {
  const dispatch = useDispatch();
  const cohykAgentMode = useSelector(
    (state) => state.ChatReducer.cohykAgentData.isAgentMode
  );
  const messages = useSelector((state) => state.ChatReducer.messages);
  const channelLastReadTs = useSelector(
    (state) => state.ChatReducer.channelData.channelLastReadTs
  );
  const activeChannel = useSelector(
    (state) => state.ChatReducer.channelData.activeChannel
  );
  const isFetchingMessages = useSelector(
    (state) => state.ChatReducer.isFetchingMessages
  );

  function handleSuccessFn(res) {
    if (res?.data?.length) {
      let recipientReadAt = null;
      // find recipient's last sent msg if present
      // & update the recipient's readAtTs on activeChannel if needed
      // for read receipts to update
      for (let i = res?.data?.length - 1; i >= 0; i--) {
        if (res?.data[i]?.sender_id == activeChannel?.recipientId) {
          recipientReadAt = res?.data[i].date_created;
          break;
        }
      }

      dispatch({
        type: "SET_MESSAGES",
        payload: [...messages, ...res?.data.map((msg) => parseMessageObj(msg))],
      });
      dispatch({
        type: "SET_SCROLL_DATA",
        payload: {
          shouldScroll: true,
          behavior: "smooth",
        },
      });
      if (recipientReadAt) {
        dispatch({
          type: "SET_ACTIVE_CHANNEL",
          payload: {
            ...activeChannel,
            recipientReadTs: recipientReadAt,
          },
        });
      }
    }

    // Updating active channel's readAtTs after fetching latest msgs
    // No calling mark-read API for cohyk agent, server doesn't store readAtTs for cohyk agent
    // But keep local state updated to sustain ping functionality
    const readAt = new Date().toISOString();
    if (cohykAgentMode) {
      dispatch({
        type: "SET_CHANNEL_LAST_READ_TS",
        payload: {
          ...channelLastReadTs,
          [activeChannel.id]: readAt,
        },
      });
    } else {
      function handleReadAtUpdateSuccessFn(res) {
        if (res?.data?.status !== "success") return;

        dispatch({
          type: "SET_CHANNEL_LAST_READ_TS",
          payload: {
            ...channelLastReadTs,
            [activeChannel.id]: readAt,
          },
        });
      }

      handlePostApiCall({
        url: `messenger/channel/${activeChannel.id}/mark-read`,
        params: {
          read_ts: readAt,
        },
        handleSuccessFn: handleReadAtUpdateSuccessFn,
      });
    }
  }

  function fetchLatestMessages() {
    if (!activeChannel || isFetchingMessages) return;

    dispatch({
      type: "SET_IS_FETCHING_MESSAGES",
    });
    const params = {
      after_read_ts: new Date(
        channelLastReadTs[activeChannel.id]
      ).toISOString(),
      cohyk_agent_mode: cohykAgentMode,
    };
    handlePostApiCall({
      url: `messenger/channel/${activeChannel.id}/latest-messages`,
      params: params,
      handleSuccessFn: handleSuccessFn,
    }).finally(() => {
      dispatch({
        type: "RESET_IS_FETCHING_MESSAGES",
      });
    });
  }

  return fetchLatestMessages;
}

function useFetchAllChannelsSuccessFnHelper() {
  const router = useRouter();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.AuthReducer.profile);
  const cohykAgentMode = useSelector(
    (state) => state.ChatReducer.cohykAgentData.isAgentMode
  );
  const channelLastReadTs = useSelector(
    (state) => state.ChatReducer.channelData.channelLastReadTs
  );

  function handleGetAllChannelsSuccessFn(res) {
    const parsedChannels = [];
    const channelLastReadTsCopy = { ...channelLastReadTs };
    const defaultDate = new Date().toISOString();
    if (res?.data?.channels?.length) {
      // `channelId`: `${lastest read timestamp by any member}` mapping
      // used to order the list of channels
      const channelOrderingTs = {};

      for (const channel of res?.data?.channels) {
        parsedChannels.push(
          parseChannelObj(
            channel,
            cohykAgentMode ? null : profile,
            cohykAgentMode,
            null
          )
        );

        if (channel?.members_read_at_ts) {
          const userReadChannelAt =
            channel?.members_read_at_ts[profile.id] ?? defaultDate;
          channelLastReadTsCopy[channel.channel_id] = userReadChannelAt;

          for (const readTs of Object.values(channel.members_read_at_ts)) {
            if (
              !channelOrderingTs[channel?.channel_id] ||
              new Date(channelOrderingTs[channel?.channel_id]) <
                new Date(readTs)
            ) {
              channelOrderingTs[channel?.channel_id] = readTs;
            }
          }
        }
      }
    }

    if (res?.data?.last_msg_ts) {
      dispatch({
        type: "SET_CHANNEL_LAST_MSG_TS",
        payload: {
          ...res?.data?.last_msg_ts,
        },
      });
    }

    dispatch({ type: "SET_CHANNELS", payload: parsedChannels });
    dispatch({
      type: "SET_CHANNEL_LAST_READ_TS",
      payload: channelLastReadTsCopy,
    });
  }

  return handleGetAllChannelsSuccessFn;
}

function usePingStatusSuccessFnHelper() {
  const dispatch = useDispatch();
  const activeChannel = useSelector(
    (state) => state.ChatReducer.channelData.activeChannel
  );
  const channelLastReadTs = useSelector(
    (state) => state.ChatReducer.channelData.channelLastReadTs
  );
  const channelLastMsgTs = useSelector(
    (state) => state.ChatReducer.channelData.channelLastMsgTs
  );
  const fetchLatestMsgs = useFetchLatestMsgs();
  const handleGetAllChannelsSuccessFn = useFetchAllChannelsSuccessFnHelper();
  const isFetchingMessages = useSelector(
    (state) => state.ChatReducer.isFetchingMessages
  );

  function handlePingSuccessFn(res) {
    if (!res?.data) return;

    // Implement notifications for chats that aren't the activeChannel
    // const toRefreshChannels = []
    let refreshActiveChannel = false;
    // let activeChannelLastReadTs = null;
    let inactiveNotifAvailable = false;
    let newChannelAvailable = false;
    const channelLastMsgTsUpdate = {
      ...channelLastMsgTs,
    };
    for (const [channelId, lastMsgTs] of Object.entries(
      res.data.channel_last_msg_ts
    )) {
      const lastMsgTsDate = new Date(lastMsgTs);
      if (
        // null check to be removed, but works as expected
        channelLastReadTs[channelId] &&
        new Date(channelLastReadTs[channelId]) < lastMsgTsDate
      ) {
        if (activeChannel && activeChannel.id == channelId) {
          refreshActiveChannel = true;
          // activeChannelLastReadTs = channelLastReadTs[activeChannel.id];
        }
      }

      // Also implement in app notification, DONE
      // Channel component compares time between lastReadTs and lastMsgTs
      // to know whether an indicator is to be displayed
      if (
        !channelLastMsgTs[channelId] ||
        new Date(channelLastMsgTsUpdate[channelId]) < lastMsgTsDate
      ) {
        inactiveNotifAvailable = true;
        channelLastMsgTsUpdate[channelId] = lastMsgTs;
      }
    }
    newChannelAvailable =
      res.data?.new_channel_available ?? newChannelAvailable;

    if (inactiveNotifAvailable) {
      dispatch({
        type: "SET_CHANNEL_LAST_MSG_TS",
        payload: { ...channelLastMsgTsUpdate },
      });
    }

    if (refreshActiveChannel) {
      fetchLatestMsgs(isFetchingMessages);
    }

    if (newChannelAvailable) {
      handleGetApiCall(
        "messenger/channels/all",
        {},
        handleGetAllChannelsSuccessFn
      );
    }
  }

  return handlePingSuccessFn;
}

function useChatPinger() {
  const router = useRouter();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isLoggedIn = useSelector((state) => state.AuthReducer.isLoggedIn);
  const cohykAgentMode = useSelector(
    (state) => state.ChatReducer.cohykAgentData.isAgentMode
  );
  const isCohykAgentModeLoading = useSelector(
    (state) => state.ChatReducer.cohykAgentData.isAgentModeLoading
  );
  const intervalRef = useRef(null);
  const isChatDialogOpen = useSelector((state) => state.ChatReducer.isOpen);
  const activeChannel = useSelector(
    (state) => state.ChatReducer.channelData.activeChannel
  );
  const channels = useSelector(
    (state) => state.ChatReducer.channelData.channels
  );
  const haveChannelsLoaded = useSelector(
    (state) => state.ChatReducer.channelData.haveChannelsLoaded
  );
  const channelLastReadTs = useSelector(
    (state) => state.ChatReducer.channelData.channelLastReadTs
  );
  const messages = useSelector((state) => state.ChatReducer.messages);
  const channelLastMsgTs = useSelector(
    (state) => state.ChatReducer.channelData.channelLastMsgTs
  );
  const handlePingSuccessFn = usePingStatusSuccessFnHelper();
  const handleGetAllChannelsSuccessFn = useFetchAllChannelsSuccessFnHelper();
  const isFetchingMessages = useSelector(
    (state) => state.ChatReducer.isFetchingMessages
  );

  useEffect(() => {
    if (!isLoggedIn) return;

    function handleGetCohykAgentDetails(res) {
      const cohykAgent = parseAgentObj(res?.data);
      dispatch({ type: "SET_COHYK_AGENT_DETAILS", payload: cohykAgent });
    }

    handleGetApiCall(
      "messenger/cohyk-agent/details",
      {},
      handleGetCohykAgentDetails
    );
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (
      isCohykAgentModeLoading &&
      (router.pathname === "/dashboard/cohyk-agent" ||
        router.pathname === "/dashboard/cohyk-agent/[channel_id]")
    )
      return;

    function handleGetAllChannelsSuccessFnWrapper(res) {
      handleGetAllChannelsSuccessFn(res);
      dispatch({ type: "SET_CHANNELS_HAVE_LOADED" });
    }

    handleGetApiCall(
      cohykAgentMode
        ? `messenger/channels/all/cohyk-agent`
        : "messenger/channels/all",
      {},
      handleGetAllChannelsSuccessFnWrapper
    );

    // Get user's messenger profile
    function handleGetMessengerProfileSuccessFn(res) {
      const messengerProfile = parseMessengerProfileObj(res?.data);
      dispatch({ type: "SET_MESSENGER_PROFILE", payload: messengerProfile });
    }

    handleGetApiCall(
      "messenger/profile/me",
      {},
      handleGetMessengerProfileSuccessFn
    );
  }, [isLoggedIn, isCohykAgentModeLoading, cohykAgentMode]);

  // Send and update mark-read on a channel when it's set active
  useEffect(() => {
    if (!isLoggedIn || !haveChannelsLoaded || !activeChannel) return;

    if (activeChannel?.id) {
      const readAt = new Date().toISOString();
      if (cohykAgentMode) {
        // No calling mark-read API for cohyk agent, only update local state
        dispatch({
          type: "SET_CHANNEL_LAST_READ_TS",
          payload: {
            ...channelLastReadTs,
            [activeChannel.id]: readAt,
          },
        });
        return;
      }

      function handleReadAtUpdateSuccessFn(res) {
        if (res?.data?.status !== "success") return;

        dispatch({
          type: "SET_CHANNEL_LAST_READ_TS",
          payload: {
            ...channelLastReadTs,
            [activeChannel.id]: readAt,
          },
        });
      }

      handlePostApiCall({
        url: `messenger/channel/${activeChannel.id}/mark-read`,
        params: {
          read_ts: readAt,
        },
        handleSuccessFn: handleReadAtUpdateSuccessFn,
      });
    }
  }, [isLoggedIn, haveChannelsLoaded, activeChannel, cohykAgentMode]);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (!haveChannelsLoaded) return;
    if (!channels?.length) return;
    if (isFetchingMessages) {
      clearInterval(intervalRef.current);
      return;
    }

    let params = {
      channel_ids: channels
        .filter((channel) => !channel.isArchived)
        .map((channel) => channel.id),
      cohyk_agent_mode: cohykAgentMode,
    };

    let intervalSec = PING_INTERVAL.DEFAULT;
    if (activeChannel?.id) {
      intervalSec = PING_INTERVAL.IN_CHAT;
      // Request only active channel's ping status when on mobile
      // Desktop will request all channels' ping status,
      // since the notification dot needs to be displayed &
      // the channel list is visible beside the messages
      if (isMobile) params.channel_ids = [activeChannel.id];
    } else if (
      isChatDialogOpen ||
      (cohykAgentMode && router.pathname === "/dashboard/cohyk-agent") ||
      router.pathname === "/messages"
    )
      intervalSec = PING_INTERVAL.IN_CHANNEL_LIST;

    intervalRef.current = setInterval(() => {
      handlePostApiCall({
        url: "messenger/ping-status",
        params: params,
        disableToast: true,
        handleSuccessFn: handlePingSuccessFn,
      });
    }, intervalSec * 1000);

    return () => clearInterval(intervalRef.current);
  }, [
    isLoggedIn,
    haveChannelsLoaded,
    channels,
    activeChannel,
    channelLastReadTs,
    messages,
    channelLastMsgTs,
    isChatDialogOpen,
    router.pathname,
    cohykAgentMode,
    isFetchingMessages,
  ]);
}

export { usePingStatusSuccessFnHelper, useFetchLatestMsgs };
export default useChatPinger;
