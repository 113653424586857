import React from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import Image from "next/image";
import ALink from "../../ui/link";
import { fromNow } from "../../../utils/date";
import styles from "../chat.module.css";

function ChannelItem({
  channel,
  className = "",
  handleChannelClick = () => {},
  wrapInLink = false,
}) {
  const router = useRouter();
  const channelLastReadTs = useSelector(
    (state) => state.ChatReducer.channelData.channelLastReadTs
  );
  const channelLastMsgTs = useSelector(
    (state) => state.ChatReducer.channelData.channelLastMsgTs
  );
  const cohykAgentMode = useSelector(
    (state) => state.ChatReducer.cohykAgentData.isAgentMode
  );
  const hostId = router.query?.host_id;
  const lastReadTs = channelLastReadTs[channel.id];
  const lastMsgTs = channelLastMsgTs[channel.id];

  const componentElements = (
    <Box
      className={[styles.chatSection__channel, className].join(" ")}
      onClick={handleChannelClick}
    >
      <Box className={styles.chatSection__channel__detail}>
        <Image
          className={styles.chatSection__channel__img}
          src={channel.img}
          width="50px"
          height="50px"
        />
        <Box>
          <Typography className={styles.chatSection__channel__name} noWrap>
            {channel.name}
          </Typography>
          {channel.isArchived && (
            <Typography className={styles.chatSection__channel__time}>
              Archived
            </Typography>
          )}
          {!channel.isArchived && lastMsgTs && (
            <Typography className={styles.chatSection__channel__time}>
              {fromNow(lastMsgTs)}
            </Typography>
          )}
        </Box>
        {lastMsgTs &&
          lastReadTs &&
          new Date(lastMsgTs) > new Date(lastReadTs) && (
            <Box className={styles.chatSection__channel__notifDotCtn}>
              <Box className={styles.chatSection__channel__notifDot} />
            </Box>
          )}
      </Box>
    </Box>
  );

  if (wrapInLink && router.query?.channel_id != channel.id) {
    return (
      <ALink
        href={
          cohykAgentMode
            ? hostId
              ? `/dashboard/cohyk-agent/${channel.id}?host_id=${hostId}`
              : `/dashboard/cohyk-agent/${channel.id}`
            : `/messages/${channel.id}`
        }
      >
        {componentElements}
      </ALink>
    );
  }

  return componentElements;
}

function ChatChannelList({
  channels = [],
  channelClassName = "",
  classListClassName = "",
  wrapInLink,
  handleChannelClick = () => {},
}) {
  const channelLastMsgTs = useSelector(
    (state) => state.ChatReducer.channelData.channelLastMsgTs
  );

  return (
    <Box
      className={[styles.chatSection__channelListCtn, classListClassName].join(
        " "
      )}
    >
      {[...channels]
        .sort((a, b) => {
          // channel 'a' doesn't have it's lastMsgTs, push to the bottom
          if (!channelLastMsgTs[a.id]) return 1;
          // channel 'b' doesn't have it's lastMsgTs, push a above b
          if (!channelLastMsgTs[b.id]) return -1;

          if (
            new Date(channelLastMsgTs[a.id]) > new Date(channelLastMsgTs[b.id])
          ) {
            return -1;
          } else if (
            new Date(channelLastMsgTs[a.id]) < new Date(channelLastMsgTs[b.id])
          )
            return 1;

          return 0;
        })
        .map((channel) => (
          <ChannelItem
            key={channel.id}
            channel={channel}
            className={channelClassName}
            wrapInLink={wrapInLink}
            handleChannelClick={() => handleChannelClick(channel)}
          />
        ))}
    </Box>
  );
}

export { ChatChannelList };
export default ChannelItem;
