import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/system";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FeedbackDialog from "../components/navbar/components/feedback/feedback.dialog";
import GATag from "../components/google-analytics/tag";
import {
  getProfileFromAPI,
  getDefaultProfileObject,
  PROFILE_COOKIES,
} from "../components/auth/profile";
import { getCookie } from "../utils/cookie";
import config from "../config";
import { useRouter } from "next/router";
import ChatSection from "../components/chat/chat";
import useChatPinger from "../components/chat/chat.pinger";
import { initFirebase } from "../firebase.config";
import { Dialog, CircularProgress } from "@mui/material";

export default function BaseLayout(props) {
  const { children } = props;

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.AuthReducer.profile);
  const router = useRouter();
  useChatPinger();
  const [routing, setRouting] = useState(false);

  useEffect(() => {
    const loggedInCookie = getCookie(PROFILE_COOKIES.IS_LOGGED_IN);
    dispatch({ type: "SET_PROFILE_LOADING" });

    if (loggedInCookie) {
      initFirebase();
      getProfileFromAPI()
        .then((profile) => {
          dispatch({
            type: "SET_USER_PROFILE",
            payload: profile,
          });
          dispatch({ type: "SET_PROFILE_LOADED" });
        })
        .catch(() => dispatch({ type: "SET_PROFILE_LOADED" }));
    } else {
      const hostName = window.location.hostname;
      if (
        config.ENVIRONMENT === "production" &&
        hostName.includes("staging.cohyk.com")
      ) {
        router.push("https://cohyk.com");
      }

      const profile = getDefaultProfileObject();
      dispatch({
        type: "SET_USER_PROFILE",
        payload: profile,
      });
      dispatch({ type: "SET_PROFILE_LOADED" });
    }
  }, []);

  useEffect(() => {
    const handleStart = (url) => {
      if (typeof url === "string") {
        const basePath = url.split("?")[0];
        // Nexjs router triggers routeChangeStart when query params change as well
        // So we need to check if the path has changed
        if (basePath !== window.location.pathname) {
          setRouting(true);
        }
      }
    };

    const handleComplete = (url) => {
      setRouting(false);
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, []);

  return (
    <>
      <Box>
        <Dialog
          open={routing}
          PaperProps={{
            className: "route__loadingCtn",
          }}
        >
          <CircularProgress color="primary" />
        </Dialog>
        {children}
      </Box>
      <FeedbackDialog />
      <ToastContainer
        position="top-center"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <GATag />
      {!profile?.isCohykAgent && <ChatSection />}
    </>
  );
}
