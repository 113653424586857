import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import {
  Info as InfoIcon,
  PhotoSizeSelectActual,
  Send as SendIcon,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { handlePostApiCall, uploadPhoto } from "../../../utils/api.call";
import { useFetchLatestMsgs } from "../chat.pinger";
import { usePermitNotifications } from "../../../firebase.config";
import { getHostStatusText, parseChannelObj, parseMessageObj } from "../chat";
import useIsMobile from "../../../utils/device.type.hook";
import styles from "../chat.module.css";

const ARCHIVED_TEXT_CUSTOMER =
  "This chat is archived. This host will not be able to send messages to you. Unarchive to send a message";
const ARCHIVED_TEXT_HOST =
  "This chat has been archived by the customer. You cannot write to them.";

function ChatInput(
  {
    // value,
    // onChange = () => {},
    // onSend = () => {},
    // isArchived = false,
  }
) {
  const router = useRouter();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [messageInput, setMessageInput] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const imageInputRef = useRef(null);

  const isLoggedIn = useSelector((state) => state.AuthReducer.isLoggedIn);
  const profile = useSelector((state) => state.AuthReducer.profile);
  const cohykAgentMode = useSelector(
    (state) => state.ChatReducer.cohykAgentData.isAgentMode
  );
  const activeChannel = useSelector(
    (state) => state.ChatReducer.channelData.activeChannel
  );
  const tempChannel = useSelector(
    (state) => state.ChatReducer.channelData.tempChannel
  );
  const channels = useSelector(
    (state) => state.ChatReducer.channelData.channels
  );
  const messages = useSelector((state) => state.ChatReducer.messages);
  const fetchLatestMsgs = useFetchLatestMsgs();

  const { permission: notifPermission, handlePermission: handlePermissions } =
    usePermitNotifications();
  const [isNotifDialogOpen, setIsNotifDialogOpen] = useState(false);

  useEffect(() => {
    if (router.query?.text) {
      setMessageInput(decodeURIComponent(router.query.text));
      const queries = router.query;
      delete queries["text"];
      router.replace(
        {
          query: queries,
        },
        undefined,
        {
          shallow: true,
          scroll: false,
        }
      );
    }
  }, [router.query?.text]);

  function handleMessageInput(e) {
    setMessageInput(e.target.value);
  }

  function commonResHandler(res) {
    fetchLatestMsgs();

    if (notifPermission === "default" && messages?.length === 0) {
      handleNotifDialogOpen();
    }

    // Unarchive UI if customer sends a msg on an archived channel
    if (!profile.isHost && activeChannel?.isArchived) {
      const newChannelState = {
        ...activeChannel,
      };
      newChannelState.isArchived = false;

      dispatch({ type: "SET_ACTIVE_CHANNEL", payload: newChannelState });
      dispatch({
        type: "SET_CHANNELS",
        payload: channels.map((ch) =>
          ch.id === newChannelState.id ? newChannelState : ch
        ),
      });
    }
  }

  function handleSendMessage() {
    if (
      !isLoggedIn ||
      isSending ||
      isUploading ||
      (profile.isHost && activeChannel?.isArchived)
    )
      return;

    if (!messageInput.trim()) {
      return;
    }

    setIsSending(true);

    if (tempChannel) {
      const params = {
        members: [
          profile.id,
          tempChannel.recipientHandle || tempChannel.recipientId,
        ],
      };

      let creationFailed = false;
      function handleSuccessFn(res) {
        if (res?.data?.detail?.status === "failed") {
          toast(res?.data?.detail?.message, {
            className: "Toastify__toast__background-danger",
            bodyClassName: "toast__body",
            autoClose: 3000,
          });
          return;
        }

        const parsedChannel = parseChannelObj(res?.data, profile);
        dispatch({
          type: "SET_CHANNELS",
          payload: [parsedChannel, ...channels],
        });

        function handleSendMessageSuccessFn(res) {
          dispatch({
            type: "SET_ACTIVE_CHANNEL",
            payload: parsedChannel,
          });
          if (isMobile || router.pathname !== "/messages") {
            router.push(`/messages/${parsedChannel.id}`);
          } else {
            // EXCEPTION: Adding message to redux through here
            const message = parseMessageObj(res?.data);
            dispatch({ type: "ADD_MESSAGE", payload: message });
          }
          dispatch({ type: "RESET_TEMP_CHANNEL" });
        }
        sendMessage(parsedChannel.id, handleSendMessageSuccessFn);
      }

      handlePostApiCall({
        url: "messenger/channel/create",
        params: params,
        handleSuccessFn: handleSuccessFn,
        handleFailureFn: () => {
          creationFailed = true;
        },
      });

      if (creationFailed) {
        setIsSending(false);
        return;
      }
    } else sendMessage(activeChannel?.id);
  }

  function sendMessage(channelId, handleSuccessFnParam = () => {}) {
    const params = {
      sender_id: profile.id,
      message_text: messageInput,
      cohyk_agent_mode: cohykAgentMode,
    };

    function handleSuccessFn(res) {
      if (res?.data?.detail?.status === "failed") {
        toast(res?.data?.detail?.message, {
          className: "Toastify__toast__background-danger",
          bodyClassName: "toast__body",
          autoClose: 3000,
        });
        return;
      }

      setMessageInput("");
      commonResHandler(res);
      handleSuccessFnParam(res);
    }

    handlePostApiCall({
      url: `messenger/channel/${channelId}/message`,
      params,
      handleSuccessFn,
    }).finally(() => setIsSending(false));
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  }

  function handleChooseImageClick() {
    if (
      !isLoggedIn ||
      isSending ||
      isUploading ||
      (profile.isHost && activeChannel?.isArchived)
    )
      return;

    imageInputRef.current.click();
  }

  async function handleImageUpload(e) {
    if (
      !isLoggedIn ||
      isSending ||
      isUploading ||
      (profile.isHost && activeChannel?.isArchived)
    )
      return;

    if (e.target.files.length === 0) return;

    const params = {
      entity_type: "channel",
      media_for_type: "message",
    };
    if (cohykAgentMode) {
      params.cohyk_agent_mode = cohykAgentMode;
    }

    setIsUploading(true);
    const res = await uploadPhoto(
      e,
      params,
      `messenger/channel/${activeChannel.id}/message-media`,
      undefined,
      undefined,
      "media"
    ).finally(() => setIsUploading(false));

    if (res?.detail?.status === "failed") {
      toast(res.detail.message, {
        className: "Toastify__toast__background-danger",
        bodyClassName: "toast__body",
        autoClose: 3000,
      });
    } else {
      commonResHandler(res);
    }
  }

  function handleNotifDialogOpen() {
    setIsNotifDialogOpen(true);
  }

  function handleNotifDialogClose() {
    setIsNotifDialogOpen(false);
  }

  function handleNotifDialogSubmit() {
    handlePermissions();
    handleNotifDialogClose();
  }

  if (activeChannel?.isArchived && profile?.isHost) {
    return (
      <Box
        className={[
          styles.chatSection__inputOuterCtn,
          styles.chatSection__inputOuterCtn__archivedHost,
        ].join(" ")}
      >
        <Typography className={styles.chatSection__input__archivedHostText}>
          <InfoIcon color="neutral-700" />
          {ARCHIVED_TEXT_HOST}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        className={[
          styles.chatSection__inputOuterCtn,
          activeChannel?.recipientIsUnavailable &&
            styles.chatSection__inputOuterCtn__recipientUnavailable,
        ].join(" ")}
      >
        {(activeChannel?.isArchived && !profile?.isHost) ||
          (activeChannel?.recipientIsUnavailable && (
            <Typography
              className={styles.chatSection__input__archivedCustomerText}
            >
              <InfoIcon
                className={
                  styles.chatSection__input__archivedCustomerText__icon
                }
                color="neutral-700"
              />
              {activeChannel?.isArchived
                ? ARCHIVED_TEXT_CUSTOMER
                : activeChannel?.recipientIsUnavailable
                ? getHostStatusText(
                    activeChannel?.recipientStatus,
                    activeChannel?.recipientStatusActiveUntil
                  )
                : ""}
            </Typography>
          ))}
        <Box className={styles.chatSection__inputCtn}>
          <TextareaAutosize
            className={styles.chatSection__input}
            minRows={1}
            maxRows={4}
            value={messageInput}
            onChange={handleMessageInput}
            onKeyDown={handleKeyDown}
            placeholder="Start typing..."
          />
          <Box className={styles.chatSection__inputBtnsCtn}>
            <IconButton onClick={handleChooseImageClick}>
              {isUploading ? (
                <CircularProgress size={20} />
              ) : (
                <PhotoSizeSelectActual color="neutral-700" />
              )}
            </IconButton>
            <IconButton
              className={styles.chatSection__inputBtn}
              onClick={handleSendMessage}
            >
              {isSending ? (
                <CircularProgress color="secondary" size={24} />
              ) : (
                <SendIcon color="secondary" />
              )}
            </IconButton>
          </Box>
        </Box>
      </Box>
      <input
        type="file"
        accept="image/jpg,image/jpeg,image/png"
        style={{ width: 0, height: 0, position: "absolute" }}
        onChange={handleImageUpload}
        ref={imageInputRef}
      />
      <Dialog
        open={isNotifDialogOpen}
        onClose={handleNotifDialogClose}
        PaperProps={{
          className: styles.chatSection__input__enableNotifsDialog,
        }}
      >
        <DialogTitle
          className={styles.chatSection__input__enableNotifsDialog__header}
        >
          Enable browser notifications
        </DialogTitle>
        <DialogContent>
          <Typography
            className={styles.chatSection__input__enableNotifsDialog__text}
          >
            Would you like to receive browser notifications when there is a new
            message or a reply?
          </Typography>
          <Typography
            className={styles.chatSection__input__enableNotifsDialog__text}
            sx={{
              marginTop: "16px",
            }}
          >
            A browser pop-up will appear after clicking "Yes". Click
            "Enable/Allow" to turn on notifications from Cohyk.
          </Typography>
          <Typography
            className={styles.chatSection__input__enableNotifsDialog__text}
            sx={{
              marginTop: "16px",
            }}
          >
            <b>Note: </b> Before enabling, make sure your browser has permission
            to display notifications on your device.
          </Typography>
        </DialogContent>
        <DialogActions
          className={styles.chatSection__input__enableNotifsDialog__btnsCtn}
        >
          <Button
            className={styles.chatSection__input__enableNotifsDialog__btns}
            onClick={handleNotifDialogClose}
            variant="outlined"
            sx={{
              "&, &:hover": {
                borderColor: "#94A3B8",
              },
            }}
          >
            No
          </Button>
          <Button
            className={styles.chatSection__input__enableNotifsDialog__btns}
            onClick={handleNotifDialogSubmit}
            variant="contained"
            sx={{
              color: "white",
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ChatInput;
