import React from "react";
import Slider from "react-slick";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import styles from "./carousel.module.css";
import { Box, Dialog } from "@mui/material";
import useIsMobile from "../../utils/device.type.hook";

const ArrowLeft = (props) => (
  <Box {...props} className={styles.carousel__prevBtn}>
    <KeyboardArrowLeftIcon />
  </Box>
);

const ArrowRight = (props) => (
  <Box {...props} className={styles.carousel__nextBtn}>
    <KeyboardArrowRightIcon />
  </Box>
);

const settings = {
  dots: false,
  speed: 500,
  infinite: false,
  slidesToShow: 2.2,
  slidesToScroll: 1,
  centerPadding: "5px",
  focusOnSelect: false,
};

const mobileSettings = {
  ...settings,
  arrows: false,
  slidesToShow: 1.1,
};

const desktopSettings = {
  ...settings,
  slidesToShow: 2.2,
  arrows: true,
  draggable: false,
  prevArrow: <ArrowLeft />,
  nextArrow: <ArrowRight />,
};

function ChildBox({ className, children }) {
  return (
    <Box className={["carousel__padder", className ?? ""].join(" ")}>
      {children}
    </Box>
  );
}

/**
 * Pass `centerItems` in either of the custom settings prop to center items in carousel
 */
function Carousel({
  children,
  customMobileSettings = {},
  customDesktopSettings = {},
  renderFullScreenDialog = false,
  isFullScreen = false,
  handleFullScreenClose,
}) {
  const isMobile = useIsMobile();

  return (
    <Box
      className={
        (customMobileSettings?.centerItems ||
          customDesktopSettings?.centerItems) &&
        styles.carousel__centerItems
      }
    >
      {isMobile && (
        <Slider {...{ ...mobileSettings, ...customMobileSettings }}>
          {children}
        </Slider>
      )}
      {!isMobile && (
        <Slider {...{ ...desktopSettings, ...customDesktopSettings }}>
          {children}
        </Slider>
      )}
      {renderFullScreenDialog && (
        <Dialog
          open={isFullScreen}
          onClose={handleFullScreenClose}
          sx={isFullScreen && { "& .carousel__padder": { paddingRight: 0 } }}
        >
          <Slider
            {...{
              ...desktopSettings,
              slidesToShow: 1,
            }}
          >
            {children}
          </Slider>
        </Dialog>
      )}
    </Box>
  );
}

export { ChildBox };
export default Carousel;
