import React, { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, CircularProgress } from "@mui/material";
import { getMeridiemTime, getOrdinalDateMonthYear } from "../../../utils/date";
import Image from "next/image";
import ReadReceipt from "../../../public/images/icons/read_receipt_double_tick.svg";
import format from "string-format";
import Markdown from "../../markdown/markdown";
import styles from "../chat.module.css";

const BaseMessage = forwardRef(
  (
    {
      message,
      ctnClassName = "",
      msgClassName = "",
      timeClassName = "",
      children,
      showReadReceipt = false,
      markdownProps = {},
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const cohykAgentDetails = useSelector(
      (state) => state.ChatReducer.cohykAgentData.agentDetails
    );

    function handleImageClick() {
      if (!message?.image) return;

      dispatch({
        type: "SET_MEDIA_VIEWER_DATA",
        payload: {
          isOpen: true,
          image: message.image,
        },
      });
    }

    const isAgentMessage = message?.sent_as === cohykAgentDetails?.id;

    return (
      <Box
        className={[styles.chatSection__msgCtn, ctnClassName].join(" ")}
        ref={ref}
      >
        {isAgentMessage && (
          <Typography
            className={[
              styles.chatSection__msgText,
              styles.chatSection__msgText__agent,
            ].join(" ")}
          >
            {cohykAgentDetails?.name}
          </Typography>
        )}
        {children}
        {message?.message_text && (
          <Typography
            className={[styles.chatSection__msgText, msgClassName].join(" ")}
          >
            {message?.formatted_message?.msg_vars ? (
              <Markdown
                className={styles.chatSection__msgText__md}
                {...markdownProps}
              >
                {format(
                  message?.message_text,
                  message?.formatted_message?.msg_vars
                )}
              </Markdown>
            ) : (
              message?.message_text
            )}
          </Typography>
        )}
        {message?.image && (
          <Box
            className={styles.chatSection__msgImageCtn}
            onClick={handleImageClick}
          >
            <Image src={message.image} layout="fill" />
          </Box>
        )}
        <Typography
          className={[styles.chatSection__msgTime, timeClassName].join(" ")}
        >
          {getMeridiemTime(message?.date_created)}
          {showReadReceipt && <Image src={ReadReceipt} priority />}
        </Typography>
      </Box>
    );
  }
);

const UserMessage = forwardRef(
  (
    {
      message,
      ctnClassName = "",
      msgClassName = "",
      ptrClassName = "",
      isFirst = false,
      lastReadTs,
    },
    ref
  ) => {
    const isMsgRead =
      lastReadTs &&
      message?.date_created &&
      new Date(lastReadTs) > new Date(message?.date_created);

    return (
      <BaseMessage
        message={message}
        ctnClassName={[
          styles.chatSection__userMsgCtn,
          isFirst && styles.chatSection__msgCtn__userMsgCtn__first,
          ctnClassName,
        ].join(" ")}
        msgClassName={[msgClassName].join(" ")}
        markdownProps={{
          linkAProps: {
            className: styles.chatSection__userMsgCtn__link,
          },
        }}
        showReadReceipt={isMsgRead}
        ref={ref}
      >
        {isFirst && (
          <Box
            className={[
              styles.chatSection__msgCtn__userMsgCtn__first__pointer,
              ptrClassName,
            ].join(" ")}
          />
        )}
      </BaseMessage>
    );
  }
);

const RecipientMessage = forwardRef(
  (
    {
      message,
      ctnClassName = "",
      msgClassName = "",
      ptrClassName = "",
      isFirst = false,
      channel,
    },
    ref
  ) => {
    const cohykAgentDetails = useSelector(
      (state) => state.ChatReducer.cohykAgentData.agentDetails
    );
    const cohykAgentMode = useSelector(
      (state) => state.ChatReducer.cohykAgentData.isAgentMode
    );
    const isAgentMessage = message?.sent_as === cohykAgentDetails?.id;
    const memberImage = isAgentMessage
      ? cohykAgentDetails?.imgCdnUrl
      : cohykAgentMode
      ? channel?.memberImages[message?.sender_id]
      : channel?.img;

    return (
      <Box
        className={[
          isFirst
            ? styles.chatSection__msgCtn__recipientMsgCtn__firstCtn
            : styles.chatSection__msgCtn__recipientMsgCtn__outerCtn,
        ].join(" ")}
        ref={ref}
      >
        {isFirst && (
          <Box
            className={
              styles.chatSection__msgCtn__recipientMsgCtn__first__imgCtn
            }
          >
            <Image src={memberImage} layout="fill" />
          </Box>
        )}
        <BaseMessage
          message={message}
          ctnClassName={[
            styles.chatSection__recipientMsgCtn,
            isFirst && styles.chatSection__msgCtn__recipientMsgCtn__first,
            ctnClassName,
          ].join(" ")}
          msgClassName={msgClassName}
          timeClassName={styles.chatSection__recipientMsgCtn__time}
        >
          {isFirst && (
            <Box
              className={[
                styles.chatSection__msgCtn__recipientMsgCtn__first__pointer,
                ptrClassName,
              ].join(" ")}
            />
          )}
        </BaseMessage>
      </Box>
    );
  }
);

function DateSeparator({ message }) {
  return (
    <Box className={styles.chatSection__msg__dateSeparatorCtn}>
      <Typography className={styles.chatSection__msg__dateSeparatorCtn__text}>
        {getOrdinalDateMonthYear(message?.date_created)}
      </Typography>
    </Box>
  );
}

const MessageRenderer = forwardRef(
  ({ isFetching = false, userMsgProps = {} }, ref) => {
    const profile = useSelector((state) => state.AuthReducer.profile);
    const cohykAgentMode = useSelector(
      (state) => state.ChatReducer.cohykAgentData.isAgentMode
    );
    const cohykAgentDetails = useSelector(
      (state) => state.ChatReducer.cohykAgentData.agentDetails
    );
    const activeChannel = useSelector(
      (state) => state.ChatReducer.channelData.activeChannel
    );
    const messages = useSelector((state) => state.ChatReducer.messages);

    return (
      <>
        {isFetching && (
          <Box className={styles.chatSection__msg__spinnerCtn}>
            <CircularProgress color="primary" />
          </Box>
        )}
        {messages.map((message, idx) => {
          const toShowDateSeparator =
            idx === 0 ||
            (messages.length &&
              new Date(messages[idx - 1].date_created).toLocaleDateString() <
                new Date(message.date_created).toLocaleDateString());
          return (
            <>
              {toShowDateSeparator && <DateSeparator message={message} />}
              {message?.sender_id == profile?.id ||
              (cohykAgentMode && message?.sent_as === cohykAgentDetails?.id) ? (
                <UserMessage
                  key={message.id}
                  message={message}
                  isFirst={
                    idx === 0 ||
                    messages[idx - 1]?.sender_id !== message?.sender_id
                  }
                  lastReadTs={activeChannel?.recipientReadTs}
                  ref={idx === 0 ? ref : null}
                  {...userMsgProps}
                />
              ) : (
                <RecipientMessage
                  key={message.id}
                  message={message}
                  isFirst={
                    idx === 0 ||
                    messages[idx - 1]?.sender_id !== message?.sender_id
                  }
                  channel={activeChannel}
                  ref={idx === 0 ? ref : null}
                />
              )}
            </>
          );
        })}
      </>
    );
  }
);

export {
  BaseMessage,
  UserMessage,
  RecipientMessage,
  DateSeparator,
  MessageRenderer,
};
