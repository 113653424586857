import { handleGetApiCall } from "../../../utils/api.call";
import Location, { parseLocationObject } from "../../location/location";

function loadData(dataHandler) {
  const successFn = (response) => {
    if (response.data?.locations?.length > 0) {
      const locs = [];
      const responseLocations = response.data.locations;
      for (let i = 0; i < responseLocations.length && i < 5; i++) {
        // locs.push(new Location(responseLocations[i]));
        locs.push(parseLocationObject(responseLocations[i]));
      }
      dataHandler(locs);
    }
  };

  const response = handleGetApiCall("location/popular", {}, successFn);
  return response;
}

export { loadData };
