import { useState, useEffect } from "react";

const defaultObserverOpts = {
  root: null,
  threshold: 0.5,
};

function useScrollToComponent(
  elementRef,
  useEffectDeps = [],
  observerOpts = defaultObserverOpts,
  detectOnce = false
) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (elementRef.current) {
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (detectOnce) observer.disconnect();
        } else setIsVisible(false);
      }, observerOpts);

      observer.observe(elementRef.current);

      return () => observer.disconnect();
    }
  }, [elementRef.current, ...useEffectDeps]);

  return isVisible;
}

export default useScrollToComponent;
