import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { Box, Button, Typography } from "@mui/material";
import { handlePostApiCall } from "../../utils/api.call";
import { parseProfileObject } from "../auth/profile";
import Image from "next/image";
import MsgBubble from "../../public/images/icons/msg_bubble.svg";
import {
  getDateInHyphenSeparatorFormat,
  getDateAndMonth,
} from "../../utils/date";
import { getDefaultProfileObject } from "../auth/profile";
import useIsMobile from "../../utils/device.type.hook";
import useScrollToComponent from "../../utils/use.scroll.to.component";
import ChatSectionHeader, {
  StatusUntilDatePicker,
} from "./components/chat.section.header";
import { MessageRenderer } from "./components/message";
import ChatInput from "./components/input";
import { ChatChannelList } from "./components/channel";
import NoChannelsSection from "./components/no.channels";
import MediaViewer from "../ui/media-viewer/media.viewer";
import styles from "./chat.module.css";

function getDefaultChannelObj() {
  const channelObj = {
    id: "",
    // name = other user's name
    name: "",
    // img = other user's profile picture
    img: "",
    isArchived: false,
    // Assuming 2 members in a channel
    recipientId: "",
    recipientReadTs: null,
    recipientIsHost: false,
    recipientHandle: "",
    recipientIsUnavailable: false,
    recipientStatus: null,
    recipientStatusActiveUntil: null,
    activeAgentProfileId: null,
    memberImages: {},
  };

  return channelObj;
}

function parseChannelObj(
  res,
  userProfile,
  cohykAgentMode = false,
  hostId = null
) {
  const channelObj = getDefaultChannelObj();
  channelObj.id = res?.channel_id;
  channelObj.isArchived = res?.is_archived;
  channelObj.activeAgentProfileId = res?.active_agent_profile_id;
  // NOTE: Assuming that there are only 2 users in a channel
  const memberProfiles = res?.member_profiles?.map((member) =>
    parseMessengerProfileObj(member)
  );
  let otherUserMessengerProfile = null;
  if (cohykAgentMode) {
    // If hostId is defined, then we're looking at a channel that's come through search (get host channels)
    if (hostId) {
      otherUserMessengerProfile = memberProfiles?.find(
        (member) => member?.host?.id != hostId
      );
    } else {
      // If not, then it's a channel that agent is active on
      otherUserMessengerProfile = memberProfiles?.find(
        // Find a member that's not a host, that's the traveller
        (member) => member?.isHost === false
      );
      if (!otherUserMessengerProfile) {
        // Couldn't find a member that's a traveller, so pick the first member (can't know which host is the traveller tho)
        otherUserMessengerProfile = memberProfiles[0];
      }
    }
    // Join the users names, members are clear to the active agent
    channelObj.name = memberProfiles
      ?.map((member) => member?.displayName)
      ?.join(", ");
  } else {
    otherUserMessengerProfile = memberProfiles?.find(
      (member) => member?.id != userProfile?.id
    );
    channelObj.name = otherUserMessengerProfile?.displayName;
  }
  channelObj.img = otherUserMessengerProfile?.hostPhotoURL;
  channelObj.recipientId = otherUserMessengerProfile.id;
  channelObj.recipientReadTs = res?.members_read_at_ts
    ? res.members_read_at_ts[otherUserMessengerProfile.id]
    : channelObj.recipientReadTs;
  channelObj.recipientIsHost = otherUserMessengerProfile.isHost;
  channelObj.recipientHandle = otherUserMessengerProfile.instagramHandle;
  channelObj.recipientIsUnavailable =
    otherUserMessengerProfile.status?.id !== "available";
  channelObj.recipientStatus = otherUserMessengerProfile.status;
  channelObj.recipientStatusActiveUntil =
    otherUserMessengerProfile.statusActiveUntil;
  channelObj.memberImages = memberProfiles?.reduce((acc, member) => {
    acc[member.id] = member.hostPhotoURL;
    return acc;
  }, {});
  return channelObj;
}

function getDefaultMessageObj() {
  const messageObj = {
    id: 0,
    channel_id: "",
    sender_id: 0,
    message_text: "",
    date_created: null,
    image: "",
    formatted_message: null,
    sent_as: "",
  };

  return messageObj;
}

function parseMessageObj(res) {
  const messageObj = getDefaultMessageObj();
  messageObj.id = res?.id ?? messageObj.id;
  messageObj.channel_id = res?.channel_id ?? messageObj.channel_id;
  messageObj.sender_id = res?.sender_id ?? messageObj.sender_id;
  messageObj.message_text = res?.message_text ?? messageObj.message_text;
  messageObj.date_created = res?.date_created ?? messageObj.date_created;
  messageObj.image = res?.formatted_message?.image ?? messageObj.image;
  messageObj.formatted_message =
    res?.formatted_message ?? messageObj.formatted_message;
  messageObj.sent_as = res?.sent_as ?? messageObj.sent_as;

  return messageObj;
}

function getDefaultMessengerProfileObj() {
  const profile = {
    ...getDefaultProfileObject(),
    profileId: "",
    status: null,
    statusActiveUntil: null,
  };

  return profile;
}

function parseMessengerProfileObj(res) {
  let profile = {
    ...getDefaultMessengerProfileObj(),
  };
  if (!res) {
    return profile;
  }
  profile = {
    ...profile,
    ...parseProfileObject(res?.profile),
  };
  profile.status = res?.status ?? profile.status;
  profile.statusActiveUntil =
    res?.status_active_until ?? profile.statusActiveUntil;

  return profile;
}

function getDefaultAgentObj() {
  const agentObj = {
    id: 0,
    name: "",
    imgCdnUrl: "",
  };

  return agentObj;
}

function parseAgentObj(res) {
  const agentObj = getDefaultAgentObj();
  agentObj.id = res?.id ?? agentObj.id;
  agentObj.name = res?.name ?? agentObj.name;
  agentObj.imgCdnUrl =
    "https://cohyk.mo.cloudinary.net/op-v1/images/logo.png" ??
    res?.img_cdn_url ??
    agentObj.imgCdnUrl;

  return agentObj;
}

function getHostStatusText(status, activeUntil) {
  if (!status || status?.id === "available") return "";

  let text = "";
  const date = getDateAndMonth(activeUntil, "-");

  switch (status?.id) {
    case "on_a_trip":
      text = `Host is on a trip until ${date}. Expect delay in response`;
      break;
    case "unavailable":
      text = `Host is unavailable until ${date}. Expect delay in response`;
      break;
  }

  return text;
}

function useIsNotifAvailable() {
  const router = useRouter();
  const [showNotif, setShowNotif] = useState(false);
  const isLoggedIn = useSelector((state) => state.AuthReducer.isLoggedIn);
  const isChatDialogOpen = useSelector((state) => state.ChatReducer.isOpen);
  const channels = useSelector(
    (state) => state.ChatReducer.channelData.channels
  ).filter((ch) => !ch.isArchived);
  const channelLastMsgTs = useSelector(
    (state) => state.ChatReducer.channelData.channelLastMsgTs
  );
  const channelLastReadTs = useSelector(
    (state) => state.ChatReducer.channelData.channelLastReadTs
  );
  const haveChannelsLoaded = useSelector(
    (state) => state.ChatReducer.channelData.haveChannelsLoaded
  );
  const notifAudioRef = useRef(null);

  useEffect(() => {
    if (!isLoggedIn || !haveChannelsLoaded) return;

    if (!notifAudioRef.current)
      notifAudioRef.current = new Audio("/audio/chat_notification.mp3");
  }, [isLoggedIn, haveChannelsLoaded]);

  useEffect(() => {
    if (!isLoggedIn) return;
    // Disable on /message, /message/{channelId} pages
    if (router.pathname.includes("/messages")) return;
    if (!haveChannelsLoaded) return;

    let newShowNotifState = false;
    for (const ch of channels) {
      const chLastMsgTs = channelLastMsgTs[ch.id];
      const chLastReadTs = channelLastReadTs[ch.id];

      // If channel's last read ts doesn't exist but there is a last msg ts
      if (
        (chLastReadTs == undefined && chLastMsgTs !== undefined) ||
        (chLastMsgTs &&
          chLastReadTs &&
          new Date(chLastMsgTs) > new Date(chLastReadTs))
      ) {
        newShowNotifState = true;
        break;
      }
    }

    // Play notif audio if chat dialog is closed(already disabled for chat page on useEffect's line 2)
    // if notif is to be showed and audioRef is not undefined
    if (!isChatDialogOpen && newShowNotifState && notifAudioRef.current) {
      notifAudioRef.current.play();
    }
    setShowNotif(newShowNotifState);
  }, [
    isLoggedIn,
    haveChannelsLoaded,
    channels,
    channelLastMsgTs,
    channelLastReadTs,
  ]);

  return showNotif;
}

function ChatButton() {
  const dispatch = useDispatch();
  const showNotif = useIsNotifAvailable();

  function handleChatOpen() {
    dispatch({ type: "OPEN_CHAT" });
  }

  return (
    <>
      <Button className={styles.chatSection__openBtn} onClick={handleChatOpen}>
        <Image src={MsgBubble} priority />
        <Typography className={styles.chatSection__openBtn__text}>
          Chat
        </Typography>
        {showNotif && <Box className={styles.chatSection__openBtn__notifDot} />}
      </Button>
    </>
  );
}

async function getChannelHistoryApiCall(
  channelId,
  timeDelta = 0,
  handleSuccessFn = () => {},
  cohykAgentMode = false
) {
  const date = new Date();
  date.setMonth(date.getMonth() - (timeDelta + 1));
  const params = {
    last_read_ts: getDateInHyphenSeparatorFormat(date),
    time_delta: 31 * timeDelta,
    cohyk_agent_mode: cohykAgentMode,
  };

  return handlePostApiCall({
    url: `messenger/channel/${channelId}/history`,
    params,
    handleSuccessFn,
  });
}

function ChatSection() {
  const router = useRouter();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const isLoggedIn = useSelector((state) => state.AuthReducer.isLoggedIn);
  const userProfile = useSelector((state) => state.AuthReducer.profile);
  const isOpen = useSelector((state) => state.ChatReducer.isOpen);

  const channels = useSelector(
    (state) => state.ChatReducer.channelData.channels
  );
  const activeChannel = useSelector(
    (state) => state.ChatReducer.channelData.activeChannel
  );
  const tempChannel = useSelector(
    (state) => state.ChatReducer.channelData.tempChannel
  );
  const messages = useSelector((state) => state.ChatReducer.messages);

  const scrollToBottomRef = useRef(null);
  const scrollToBottom = useSelector((state) => state.ChatReducer.scrollData);

  const [isFetching, setIsFetching] = useState(false);
  const fetchMoreRef = useRef({
    timeDelta: 0,
    disable: true,
  });
  const firstMsgRef = useRef(null);
  const isFirstMsgVisible = useScrollToComponent(
    firstMsgRef,
    [isMobile],
    { root: null, threshold: 0.1 },
    true
  );
  const mediaViewer = useSelector((state) => state.ChatReducer.mediaViewerData);
  const statusChangeData = useSelector(
    (state) => state.ChatReducer.statusChangeData
  );

  useEffect(() => {
    if (scrollToBottom.shouldScroll && activeChannel?.id && messages?.length) {
      scrollToBottomRef.current?.scrollIntoView({
        behavior: scrollToBottom.behavior,
      });
      dispatch({
        type: "SET_SCROLL_DATA",
        payload: {
          shouldScroll: false,
          behavior: "instant",
        },
      });
    } else if (!activeChannel?.id) {
      dispatch({
        type: "SET_SCROLL_DATA",
        payload: {
          shouldScroll: true,
          behavior: "instant",
        },
      });
    }
  }, [scrollToBottom.shouldScroll, activeChannel, messages]);

  useEffect(() => {
    if (!isLoggedIn || fetchMoreRef.current.disable || !isFirstMsgVisible)
      return;

    if (activeChannel) {
      fetchMoreRef.current.timeDelta += 1;
      getChannelHistory(activeChannel.id, fetchMoreRef.current.timeDelta);
    }
  }, [isLoggedIn, isFirstMsgVisible]);

  if (!isLoggedIn) {
    return null;
  }

  function handleChatOpen() {
    dispatch({ type: "OPEN_CHAT" });
  }

  function handleChatClose() {
    dispatch({ type: "CLOSE_CHAT" });
    dispatch({ type: "RESET_STATUS_CHANGE_DATA" });
    handleChannelClose();
  }

  function handleChannelClose() {
    dispatch({ type: "RESET_ACTIVE_CHANNEL" });
    dispatch({ type: "RESET_MESSAGES" });
    dispatch({ type: "RESET_TEMP_CHANNEL" });
    handleMediaViewerClose();
  }

  function getChannelHistory(
    channelId,
    timeDelta = 0,
    appendPreviousMessages = true,
    shouldScrollToBottom = false
  ) {
    setIsFetching(true);

    function handleSuccessFn(res) {
      const dispatchPayload = [...res?.data.map((msg) => parseMessageObj(msg))];
      if (appendPreviousMessages) {
        dispatchPayload.push(...messages);
      }
      dispatch({ type: "SET_MESSAGES", payload: dispatchPayload });
      if (res?.data?.length === 0) {
        fetchMoreRef.current = {
          ...fetchMoreRef.current,
          disable: true,
        };
      } else if (res?.data?.length && fetchMoreRef.current.disable) {
        fetchMoreRef.current = {
          ...fetchMoreRef.current,
          disable: false,
        };
      }

      if (shouldScrollToBottom) {
        dispatch({
          type: "SET_SCROLL_DATA",
          payload: {
            shouldScroll: true,
            behavior: "instant",
          },
        });
      }
    }

    getChannelHistoryApiCall(channelId, timeDelta, handleSuccessFn).finally(
      () => setIsFetching(false)
    );
  }

  function handleChannelClick(channel) {
    dispatch({ type: "SET_ACTIVE_CHANNEL", payload: channel });

    fetchMoreRef.current = {
      timeDelta: 0,
      disable: true,
    };
    getChannelHistory(channel.id, fetchMoreRef.current.timeDelta, false, true);
  }

  function handleMediaViewerClose() {
    if (!mediaViewer?.isOpen) return;
    dispatch({ type: "RESET_MEDIA_VIEWER_DATA" });
  }

  if (
    !isLoggedIn ||
    isMobile ||
    router.pathname === "/messages" ||
    router.pathname === "/messages/[channel_id]" ||
    router.pathname === "/dashboard/cohyk-agent" ||
    router.pathname === "/dashboard/cohyk-agent/[channel_id]"
  ) {
    return <></>;
  }

  const haveNoChannels = !activeChannel && channels?.length === 0;

  return (
    <>
      <MediaViewer
        open={mediaViewer?.isOpen}
        onClose={handleMediaViewerClose}
        medias={[mediaViewer?.image]}
        renderDisplayedItemFn={(image) => <img src={image} />}
        disableSelectionItems
      />
      {!isOpen && <ChatButton />}
      {isOpen && (
        <Box
          className={[
            styles.chatSection__ctn,
            (activeChannel || tempChannel) && styles.chatSection__ctn__channel,
            haveNoChannels && styles.chatSection__ctn__noChannels,
          ].join(" ")}
        >
          <ChatSectionHeader
            activeChannel={activeChannel}
            handleChatClose={handleChatClose}
            handleChannelClose={handleChannelClose}
            enableArchive={activeChannel && userProfile?.isHost === false}
            enableStatus={!activeChannel && userProfile?.isHost}
          />
          <Box
            className={[
              !activeChannel && !tempChannel && styles.chatSection__channelsCtn,
              (activeChannel || tempChannel) && styles.chatSection__msgsCtn,
              activeChannel?.isArchived &&
                !userProfile?.isHost &&
                styles.chatSection__msgsCtn__archivedCustomer,
              activeChannel?.recipientIsUnavailable &&
                styles.chatSection__msgsCtn__recipientUnavailable,
              haveNoChannels &&
                !tempChannel &&
                styles.chatSection__msgsCtn__noChannels,
            ].join(" ")}
          >
            {userProfile?.isHost && (!activeChannel || !tempChannel) && (
              <StatusUntilDatePicker />
            )}
            {haveNoChannels &&
              !tempChannel &&
              !statusChangeData?.isDatePickerOpen && <NoChannelsSection />}
            {!activeChannel &&
              !tempChannel &&
              !statusChangeData?.isDatePickerOpen && (
                <ChatChannelList
                  channels={channels}
                  channelClassName={styles.chatSection__channel__inDialog}
                  handleChannelClick={handleChannelClick}
                />
              )}
            {!statusChangeData?.isDatePickerOpen &&
              activeChannel &&
              !tempChannel && <Box sx={{ marginTop: "auto" }} />}
            {!statusChangeData?.isDatePickerOpen &&
              activeChannel &&
              !tempChannel && (
                <MessageRenderer isFetching={isFetching} ref={firstMsgRef} />
              )}
            <Box
              sx={{
                height: 0,
              }}
              ref={scrollToBottomRef}
            />
          </Box>
          {(activeChannel || tempChannel) && <ChatInput />}
        </Box>
      )}
    </>
  );
}

export {
  ChatButton,
  getDefaultChannelObj,
  parseChannelObj,
  getDefaultMessageObj,
  parseMessageObj,
  getDefaultMessengerProfileObj,
  parseMessengerProfileObj,
  useIsNotifAvailable,
  getChannelHistoryApiCall,
  getHostStatusText,
  getDefaultAgentObj,
  parseAgentObj,
};
export default ChatSection;
