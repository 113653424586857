import { Box, IconButton } from "@mui/material";
import styles from "../navbar.module.css";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

const NavbarHamButton = ({ handleOpenNavMenu, contrastNav }) => {
  return (
    <Box className={styles.navbar__icon__box} sx={{ display: "flex" }}>
      <IconButton
        className={styles.navbar__icon}
        size="large"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuRoundedIcon color={contrastNav ? "neutral-600" : "secondary"} />
      </IconButton>
    </Box>
  );
};

export default NavbarHamButton;
