import { Tooltip } from "@mui/material";

function UITooltip({ title, children, ...rest }) {
  // If no title, disable the prop
  if (title == undefined || title == "" || title == null) {
    title = "";
  }

  return (
    <Tooltip {...rest} title={title} enterTouchDelay={50}>
      {children}
    </Tooltip>
  );
}

export default UITooltip;
