import Link from "next/link";
import React from "react";

export default function ALink({ href, children, linkProps, aProps }) {
  return (
    <Link href={href} prefetch={linkProps?.prefetch ?? false} {...linkProps} passHref>
      <a {...aProps}>{children}</a>
    </Link>
  );
}
