import React from "react";
import { Chip as MuiChip } from "@mui/material";
import styles from "./chip.module.css";
import UITooltip from "../ui/tooltip";

function Chip({
  className,
  sx,
  labelSx,
  children,
  icon,
  label,
  color,
  onDelete,
  deleteIcon,
  title,
  onClick,
}) {
  return (
    <UITooltip title={title}>
      <MuiChip
        className={[styles.chip, className ?? ""].join(" ")}
        sx={{
          "& .MuiChip-label": {
            padding: "0 5px",
            ...labelSx,
          },
          ...sx,
        }}
        icon={icon}
        label={label}
        color={color}
        onDelete={onDelete}
        deleteIcon={deleteIcon}
        onClick={onClick}
      >
        {children}
      </MuiChip>
    </UITooltip>
  );
}

export default Chip;
