import React from "react";
import ReactMarkdown from "react-markdown";
import ALink from "../ui/link";
import styles from "./markdown.module.css";

function Markdown({ className = "", linkAProps = {}, children }) {
  return (
    <ReactMarkdown
      className={[styles.markdown, className].join(" ")}
      components={{
        a: ({ node, ...props }) => {
          return (
            <ALink
              href={props.href}
              aProps={{
                className: styles.markdown__link,
                ...linkAProps,
                ...props,
              }}
            >
              {props.children}
            </ALink>
          );
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
}

export default Markdown;
