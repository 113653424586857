import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import Image from "next/image";
import ChatBot from "../../../public/images/chat_bot.svg";
import { loadData } from "../../section/popular/data";
import ALink from "../../ui/link";
import {
  useIsMessagingSupported,
  usePermitNotifications,
} from "../../../firebase.config";
import styles from "../chat.module.css";

function LocationChip({ location }) {
  return (
    <ALink
      href={`/${location.tripsUrlPath}`}
      aProps={{
        className: styles.chatSection__noChannelsCtn__loc,
      }}
    >
      {location.name}{" "}
    </ALink>
  );
}

function NoChannelsSection() {
  const [popLocations, setPopLocations] = useState([]);
  const activeChannel = useSelector(
    (state) => state.ChatReducer.channelData.activeChannel
  );
  const channels = useSelector(
    (state) => state.ChatReducer.channelData.channels
  );
  const channelsHaveLoaded = useSelector(
    (state) => state.ChatReducer.channelData.haveChannelsLoaded
  );
  const isMessagingSupported = useIsMessagingSupported();
  const { permission: notifPermission, handlePermission: handlePermissions } =
    usePermitNotifications();

  useEffect(() => {
    loadData((popLocs) => setPopLocations(popLocs));
  }, []);

  function handleNotifDialogSubmit() {
    handlePermissions();
  }

  // Wait until channelData has loaded
  if (!channelsHaveLoaded || (activeChannel && channels?.length)) return <></>;

  return (
    <Box className={styles.chatSection__noChannelsCtn}>
      <Box position="relative">
        <Image src={ChatBot} priority />
      </Box>
      <Typography className={styles.chatSection__noChannelsCtn__title}>
        Hello!
      </Typography>
      <Typography
        className={styles.chatSection__noChannelsCtn__text}
        sx={{
          marginTop: "12px",
        }}
      >
        Welcome to Cohyk chat
      </Typography>
      <Typography
        className={styles.chatSection__noChannelsCtn__text}
        sx={{
          marginTop: "12px",
        }}
      >
        You don't have any chats open with the hosts yet
      </Typography>
      {isMessagingSupported === false && (
        <Typography
          className={styles.chatSection__noChannelsCtn__text}
          sx={{ marginTop: "12px" }}
        >
          <b>Note: </b>Notifications are not supported on this browser
        </Typography>
      )}
      {isMessagingSupported && notifPermission === "default" && (
        <>
          <Typography
            className={styles.chatSection__noChannelsCtn__text}
            sx={{ marginTop: "12px" }}
          >
            Enable notifications and never miss a message
          </Typography>
          <Typography className={styles.chatSection__noChannelsCtn__text}>
            <span
              className={styles.chatSection__noChannelsCtn__enableNotifsText}
              onClick={handleNotifDialogSubmit}
            >
              Click here
            </span>{" "}
            to enable notifications
          </Typography>
        </>
      )}
      <Typography
        className={styles.chatSection__noChannelsCtn__text}
        sx={{
          marginTop: "20px",
        }}
      >
        Look for some trips and connect with hosts
      </Typography>
      <Typography className={styles.chatSection__noChannelsCtn__text}>
        Here are some recommended destinations
      </Typography>
      <Box className={styles.chatSection__noChannelsCtn__locsCtn}>
        {popLocations.map((loc) => (
          <LocationChip key={loc.id} location={loc} />
        ))}
      </Box>
    </Box>
  );
}

export default NoChannelsSection;
