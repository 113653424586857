import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Stack,
  Drawer,
} from "@mui/material";
import Image from "next/image";
import styles from "../navbar.module.css";
import CloseIcon from "@mui/icons-material/Close";

import cohykContrastIcon from "../../../public/images/cohyk_contrast.svg";
import Instagram from "../../../public/images/instagram_light.svg";
import Twitter from "../../../public/images/twitter_light.svg";
import ALink from "../../ui/link";
import { logoutUser } from "../../auth/profile";
import { captureEvent } from "../../google-analytics/tag";
import { EVENTNAME, SECTION } from "../../google-analytics/constants";
import { AUTH_TYPE } from "../../auth/login";

const NavDrawerLinks = ({
  handleRegisterHostClick,
  handleLoginClick,
  handleCreateAccountClick,
  isLoggedIn,
  isHostLoggedIn,
  setNavMenuClose,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const handleLogoutClick = async () => {
    logoutUser().then((res) => {
      if (res.status == "success") {
        dispatch({ type: "RESET_USER_PROFILE" });
        setNavMenuClose();
        router.push("/");
      }
    });
  };

  const handleFeedbackClick = () => {
    captureEvent(EVENTNAME.FEEDBACK_CLICK, SECTION.NAVBAR);
    dispatch({ type: "OPEN_FEEDBACK_MODAL" });
  };

  return (
    <Box p={"80px 16px 24px 16px"}>
      {!isLoggedIn && (
        <>
          <Typography
            className={styles.navdrawer__items}
            variant="text-base-semibold"
            component="div"
            color="secondary"
            onClick={handleLoginClick}
          >
            Login
          </Typography>
          <Typography
            className={styles.navdrawer__items}
            variant="text-base-semibold"
            component="div"
            color="secondary"
            onClick={handleCreateAccountClick}
          >
            Create Account
          </Typography>
          <Typography
            className={styles.navdrawer__items}
            variant="text-base-semibold"
            component="div"
            color="secondary"
            onClick={handleRegisterHostClick}
          >
            Sign up as host
          </Typography>
        </>
      )}
      {isHostLoggedIn && (
        <>
          <Typography
            className={styles.navdrawer__items}
            variant="text-base-semibold"
            component="div"
            color="secondary"
          >
            <ALink href="/dashboard/analytics">Analytics</ALink>
          </Typography>
          <Typography
            className={styles.navdrawer__items}
            variant="text-base-semibold"
            component="div"
            color="secondary"
          >
            <ALink href="/dashboard/manage-trips">Manage Trips</ALink>
          </Typography>
        </>
      )}
      <Typography
        className={styles.navdrawer__items}
        variant="text-base-semibold"
        component="div"
        color="secondary"
        onClick={handleFeedbackClick}
      >
        Feedback
      </Typography>
      <Typography
        className={styles.navdrawer__items}
        variant="text-base-semibold"
        component="div"
        color="secondary"
      >
        <ALink href="/faqs">FAQs</ALink>
      </Typography>
      <Typography
        className={styles.navdrawer__items}
        variant="text-base-semibold"
        component="div"
        color="secondary"
      >
        <ALink href="/terms-of-service">Terms of Service</ALink>
      </Typography>
      <Typography
        className={styles.navdrawer__items}
        variant="text-base-semibold"
        component="div"
        color="secondary"
      >
        <ALink href="/privacy">Privacy Policy</ALink>
      </Typography>
      {isLoggedIn ? (
        <Typography
          className={styles.navdrawer__items}
          variant="text-base-semibold"
          component="div"
          color="secondary"
          onClick={handleLogoutClick}
        >
          Logout
        </Typography>
      ) : null}
      <Stack
        direction="row"
        spacing={2}
        className={styles.navdrawer__iconContainer}
      >
        <Box m={"4px 8px 0 0"}>
          <Image src={Twitter} alt="Twitter logo" priority />
        </Box>
        <Box m={"0 8px 0 0"}>
          <Image src={Instagram} alt="Instagram logo" priority />
        </Box>
      </Stack>
      <Typography variant="text-sm" component="div" color="secondary">
        contact@cohyk.com
      </Typography>
    </Box>
  );
};

const NavDrawer = ({
  setNavMenuClose,
  navMenuOpen,
  isLoggedIn,
  isHostLoggedIn,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const handleLogoClick = () => router.push("/");

  const handleRegisterHostClick = () => {
    dispatch({ type: "OPEN_HOST_REGISTER_MODAL" });
    captureEvent(EVENTNAME.ADD_TRIP_CLICK, SECTION.NAVBAR);
  };
  const handleLoginClick = () => {
    dispatch({ type: "OPEN_LOGIN_MODAL" });
    captureEvent(EVENTNAME.LOGIN_CLICK, SECTION.NAVBAR);
  };
  const handleCreateAccountClick = () => {
    dispatch({
      type: "OPEN_LOGIN_MODAL",
      payload: { authType: AUTH_TYPE.REGISTER },
    });
    captureEvent(EVENTNAME.LOGIN_CLICK, SECTION.NAVBAR);
  };

  return (
    <Drawer
      variant="temporary"
      elevation={0}
      open={navMenuOpen}
      onClose={setNavMenuClose}
      ModalProps={{ keepMounted: true }}
      SlideProps={{ direction: "down" }}
      sx={{
        display: { xs: "flex", md: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: "100vw",
          height: "565px",
          backgroundColor: "#26A77C",
        },
      }}
    >
      <Box>
        <AppBar
          position="fixed"
          elevation={0}
          className={["bgcolor-navbar-brand", styles.navbar__appbar].join(" ")}
        >
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              {/* Logo */}
              <Box className={styles.navbarLogoCtn} onClick={handleLogoClick}>
                <Box className={styles.navbarLogoIcon}>
                  <Image src={cohykContrastIcon} alt="Cohyk logo" priority />
                </Box>

                <Typography
                  className={styles.navbar__title__small}
                  variant="text-2xl-semibold"
                  noWrap
                  component="span"
                  color="secondary"
                  sx={{ display: { md: "none", lg: "none" } }}
                >
                  Cohyk
                </Typography>
              </Box>

              <Box
                className={styles.navbar__icon__box}
                sx={{ display: { xs: "flex", md: "none" } }}
              >
                <IconButton
                  className={styles.navbar__icon}
                  size="large"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={setNavMenuClose}
                  color="inherit"
                >
                  <CloseIcon color="secondary" />
                </IconButton>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <NavDrawerLinks
          handleRegisterHostClick={handleRegisterHostClick}
          handleLoginClick={handleLoginClick}
          isLoggedIn={isLoggedIn}
          isHostLoggedIn={isHostLoggedIn}
          setNavMenuClose={setNavMenuClose}
          handleCreateAccountClick={handleCreateAccountClick}
        />
      </Box>
    </Drawer>
  );
};

export default NavDrawer;
