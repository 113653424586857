import React, { useState, useEffect, useRef } from "react";
import { Modal, Box, Container, IconButton, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useIsMobile from "../../../utils/device.type.hook";
import Slider from "react-slick";
import Carousel from "../carousel";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import Image from "next/image";
import PlayIcon from "../../../public/images/icons/play_icon.svg";
import styles from "./media.viewer.module.css";

const ArrowLeft = (props) =>
  props?.onClick && (
    <Box {...props} className={styles.media_viewer__prevBtn}>
      <KeyboardArrowLeft />
    </Box>
  );

const ArrowRight = (props) =>
  props?.onClick && (
    <Box {...props} className={styles.media_viewer__nextBtn}>
      <KeyboardArrowRight />
    </Box>
  );

function SlickVideo({
  src,
  controls = true,
  play,
  className = "",
  refClassName = ".slick-active",
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;

    if (play) {
      const active = ref.current.closest(refClassName);
      if (!active) {
        ref.current.pause();
      } else {
        ref.current.play();
      }
    } else {
      ref.current.pause();
    }
  }, [play]);

  return (
    <video
      className={className}
      controls={controls}
      ref={ref}
      style={{ display: "block", width: "100%" }}
    >
      <source src={src} />
    </video>
  );
}

const mobileSliderSettings = {
  dots: false,
  arrows: true,
  speed: 500,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  focusOnSelect: false,
  prevArrow: <ArrowLeft />,
  nextArrow: <ArrowRight />,
  lazyLoad: true,
};

const desktopCarouselSettings = {
  slidesToShow: 7,
  slidesToScroll: 3,
  prevArrow: <ArrowLeft />,
  nextArrow: <ArrowRight />,
};

function DefaultRenderSelectionItem({ media }) {
  return (
    <>
      {media.media_type === "image" && (
        <img src={media.cdn_url} alt={media.caption} />
      )}
      {media.media_type === "video" && (
        <>
          <Box className={styles.media_viewer__selectionItemCtn__playIcon}>
            <Image src={PlayIcon} priority />
          </Box>
          <img src={media.thumbnail_cdn_url} alt={media.caption} />
        </>
      )}
    </>
  );
}

function MediaViewer({
  open,
  onClose,
  medias = [],
  renderDisplayedItemFn = () => <></>,
  renderSelectionItemFn = null,
  renderMobileItem = () => <></>,
  activeIdx,
  setActiveItemIdx = () => {},
  disableSelectionItems = false,
}) {
  const isMobile = useIsMobile();
  const [activeIdxState, setActiveIdxState] = useState(0);
  const usedActiveIdx = activeIdx ?? activeIdxState;

  const handleSelectionClick = (idx) => () => {
    if (activeIdx !== undefined || activeIdx !== null) {
      setActiveItemIdx(idx);
    } else {
      setActiveIdxState(idx);
    }
  };

  if (isMobile) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            overflow: "visible",
          },
          elevation: 0,
        }}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <IconButton
          className={styles.media_viewer__displayCtn__closeCtn}
          onClick={onClose}
        >
          <CloseIcon
            color="secondary"
            className={styles.media_viewer__displayCtn__closeIcon}
          />
        </IconButton>
        <Slider
          {...{
            ...mobileSliderSettings,
            beforeChange: (curr, nxt) => {
              // Slider library triggers beforeChange func even when the slider is swiped,
              // but the displayed item doesn't change
              if (curr !== nxt) {
                handleSelectionClick(nxt)();
              }
            },
            initialSlide: usedActiveIdx,
          }}
          className={styles.media_viewer__mediaSlider}
        >
          {medias.map((item, idx) => (
            <Box
              key={item.id ?? idx}
              className={styles.media_viewer__mediaSliderItemCtn}
            >
              {renderMobileItem(item, idx)}
            </Box>
          ))}
        </Slider>
      </Dialog>
    );
  }

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        sx={{
          backgroundColor: "rgba(15, 23, 42, 0.64)",
        }}
      >
        <Container className={styles.media_viewer__centerCtn}>
          <Box className={styles.media_viewer__displayCtn}>
            <IconButton
              className={styles.media_viewer__displayCtn__closeCtn}
              onClick={onClose}
            >
              <CloseIcon
                color="secondary"
                className={styles.media_viewer__displayCtn__closeIcon}
              />
            </IconButton>
            <Box className={styles.media_viewer__displayContentCtn}>
              {open &&
                medias.length > 0 &&
                renderDisplayedItemFn(medias[usedActiveIdx])}
            </Box>
          </Box>
          {!disableSelectionItems && (
            <Box className={styles.media_viewer__selectionCtn}>
              {open && (
                <Carousel customDesktopSettings={desktopCarouselSettings}>
                  {medias.map((media, idx) => (
                    <Box
                      key={media?.id ?? idx}
                      className={[
                        styles.media_viewer__selectionItemCtn,
                        idx === usedActiveIdx &&
                          styles.media_viewer__selectionItemCtn__active,
                      ].join(" ")}
                      onClick={handleSelectionClick(idx)}
                    >
                      {renderSelectionItemFn ? (
                        renderSelectionItemFn(media)
                      ) : (
                        <DefaultRenderSelectionItem media={media} />
                      )}
                    </Box>
                  ))}
                </Carousel>
              )}
            </Box>
          )}
        </Container>
      </Modal>
    </>
  );
}

export { SlickVideo };
export default MediaViewer;
