import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  Typography,
  Button,
  TextField,
  Box,
  Stack,
  IconButton,
  MenuItem,
} from "@mui/material";
import styles from "../../navbar.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { handlePostApiCall } from "../../../../utils/api.call";

const FeedbackDialog = () => {
  const issueChoices = [
    {
      value: "misc",
      label: "Miscellaneous",
    },
  ];
  const dispatch = useDispatch();

  const initialState = { email: "", feedback: "" };
  const [feedbackDetails, setFeedbackDetails] = useState(initialState);
  const [submitButtonDisabled, setButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const feedbackDialogOpen = useSelector(
    (state) => state.FeedbackReducer.isFeedbackDialogModalOpen
  );

  useEffect(() => {
    if (feedbackDialogOpen) {
      setButtonDisabled(false);
      setErrorMessage("");
    }
  }, [feedbackDialogOpen]);

  const handleEmailChange = (e) => {
    setFeedbackDetails((prevState) => {
      return {
        ...prevState,
        email: e.target.value,
      };
    });
  };
  const handleFeedbackChange = (e) => {
    setFeedbackDetails((prevState) => {
      return {
        ...prevState,
        feedback: e.target.value,
      };
    });
  };

  const handleDialogClose = () => dispatch({ type: "CLOSE_FEEDBACK_MODAL" });

  const handleSubmitFeedback = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    if (!feedbackDetails.email || !feedbackDetails.feedback) {
      setErrorMessage("Please enter your email and feedback.");
      setButtonDisabled(false);
      return;
    }

    const params = {
      email: feedbackDetails.email,
      feedback: feedbackDetails.feedback,
    };

    const handleSuccessFn = (res) => res.data;
    const feedbackRes = await handlePostApiCall({
      url: "customer/feedback",
      params,
      handleSuccessFn,
    });
    
    if (!feedbackRes) setTimeout(() => handleDialogClose(), 800);
    if (feedbackRes?.status == "success") setTimeout(() => handleDialogClose(), 800);
    else {
      setErrorMessage(feedbackRes?.message);
      setButtonDisabled(false);
    }
  };

  return (
    <Dialog
      open={feedbackDialogOpen}
      sx={{
        width: {
          xs: "auto",
          md: "404px",
        },
      }}
      className={styles.feedback_dialogBox}
    >
      <Box p={"16px 16px 24px 16px"}>
        <Stack
          className={styles.feedbackDialog__title}
          direction="row"
          spacing={1}
        >
          <Box display="flex" flexGrow={1}>
            <Typography variant="text-base" component="h1">
              Give us feedback
            </Typography>
          </Box>
          <IconButton
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 10,
            }}
          >
            <CloseIcon
              // color="neutral-400"
              color="#94A3B8"
            />
          </IconButton>
        </Stack>
        <Stack>
          <TextField
            className={styles.feedbackDialog__input}
            placeholder="Your Email"
            variant="outlined"
            onChange={handleEmailChange}
          />
          {/* <TextField
            className={styles.feedbackDialog__input}
            select
            value={selectedIssue}
            onChange={setIssue}
            label="Select an issue"
            variant="outlined"
            sx={{
              "& .MuiInputLabel-root": {
                top: "-8px",
                color: "#A2A2A2"
              }
            }}
          >
            {issueChoices.map((issue, i) => (
              <MenuItem key={i} value={issue.value}>
                {issue.name}
              </MenuItem>
            ))}
          </TextField> */}
          <TextField
            className={styles.feedbackDialog__input}
            onChange={handleFeedbackChange}
            placeholder="Your Feedback"
            variant="outlined"
            multiline
            rows={4}
            sx={{
              "& .MuiInputBase-root": {
                padding: "0px",
              },
            }}
          />
          <Typography margin="8px" variant="text-sm" color="rose-600.main">
            {errorMessage}
          </Typography>
          <Button
            className={styles.feedbackDialog__button}
            disableElevation
            variant="contained"
            onClick={handleSubmitFeedback}
            disabled={submitButtonDisabled}
          >
            <Typography
              variant="text-base-semibold"
              component="div"
              color="secondary"
            >
              Send Feedback
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default FeedbackDialog;
